const mutations = {
    INCREMENT_WHEEL: (state, val) => {
        state.incrementWheel += val;
    },
    CURRENT_SCROLL: (state, val) => {
        state.currentScroll = val;
    },
    IS_SMOOTH: (state, val) => {
        state.isSmooth = val;
    },
    LIMIT_SCROLL: (state, val) => {
        state.limitScroll = val
    },
    SCROLL_TRIGGER_INSTANCE: (state, val) => {
        state.currentScrollTriggers.push(val)
    },
    SCROLL_TRIGGER_PROGRESS: (state, val) => {
        const {uid, progress} = val
        const triggerInstance = state.currentScrollTriggers.find(trigger => trigger.uid === uid)

        if (triggerInstance) {
            triggerInstance.progress = progress
        }
    },
};

export default mutations;
