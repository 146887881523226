const actions = {
    setCursorState: (context, val) => {
        context.commit('SET_CURSOR_STATE', val)
    },
    setCursorCoords: (context, val) => {
        context.commit('SET_CURSOR_COORDS', val)
    },
    incrementHold: (context) => {
        context.commit('INCREMENT_HOLD')
    }
};

export default actions;