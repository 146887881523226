const mutations = {
    SET_CURSOR_STATE: (state, val) => {
        state.cursorState = val;
    },
    SET_CURSOR_COORDS: (state, val) => {
        state.cursorCoords = val
    },
    INCREMENT_HOLD: (state) => {
        state.holdIncrement++
    }
};

export default mutations;
