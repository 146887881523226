<template>
    <div class="c-cursor" :class="[`-${cursorState}`]">
        <div class="c-cursor_state -default"></div>
        <div class="c-cursor_state -hover"></div>
        <div class="c-cursor_state -load"></div>
        <div class="c-cursor_state -close"></div>
        <div class="c-cursor_state -hold">
            <div class="c-cursor_state_inner">
                <svg
                    class="svg-hold"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 170 170"
                >
                    <circle
                        class="svg-hold_static"
                        opacity=".5"
                        cx="85"
                        cy="85"
                        r="84.5"
                        stroke="#454545"
                        stroke-opacity=".7"
                    />
                    <circle
                        class="svg-hold_path"
                        cx="85"
                        cy="85"
                        r="84.5"
                        stroke="#2244EC"
                        stroke-miterlimit="16"
                        stroke-dashoffset="622"
                        ref="holdCircle"
                    />
                    <circle
                        class="svg-hold_indicator"
                        cx="84.346"
                        cy="84.346"
                        r="3.269"
                        fill="#fff"
                    />
                </svg>
                <span class="c-cursor_state_hint">Click & hold</span>
            </div>
        </div>
    </div>
</template>

<script>
import Raf from "quark-raf";
import { gsap } from "gsap/all";
import { mapActions, mapState } from "vuex";
import isMobile from "../../utils/isMobile";

// Get is mobile state
const isMobileObj = isMobile();
const IS_TOUCH_DEVICE = isMobileObj?.phone || isMobileObj?.tablet;

export default {
    name: "Cursor",

    computed: {
        ...mapState("cursor", ["cursorState"]),
    },

    created() {
        this.translate = {
            x: window.innerWidth * 0.5,
            y: window.innerHeight * 0.5,
            smoothX: window.innerWidth * 0.5,
            smoothY: window.innerHeight * 0.5,
            lerp: 0.08,
        };

        this.holdData = {
            isHolding: false,
            baseTime: Date.now(),
            holdDuration: 2000,
        };

        this.setCursorCoords({
            x: this.translate.x,
            y: this.translate.y,
        });

        this.isPlaying = false;

        this.onUpdateBind = this.onUpdate.bind(this);
        this.onMouseMoveBind = this.onMouseMove.bind(this);
        this.onPointerUpBind = this.onPointerUp.bind(this);
        this.onPointerDownBind = this.onPointerDown.bind(this);
    },

    mounted() {
        this.translateItem(this.translate.x, this.translate.y);

        if (IS_TOUCH_DEVICE) {
            return;
        }

        this.bindEvents();

        this.$nextTick(() => {
            this.play();
        });
    },

    beforeUnmount() {
        this.unbindEvents();
    },

    methods: {
        ...mapActions("cursor", ["setCursorCoords", "incrementHold"]),

        bindEvents() {
            window.addEventListener("mousemove", this.onMouseMoveBind);
            window.addEventListener("pointerdown", this.onPointerDownBind);
            window.addEventListener("pointerup", this.onPointerUpBind);
        },

        unbindEvents() {
            window.removeEventListener("mousemove", this.onMouseMoveBind);
            window.removeEventListener("pointerdown", this.onPointerDownBind);
            window.removeEventListener("pointerup", this.onPointerUpBind);
        },

        play() {
            if (this.isPlaying) return;
            this.isPlaying = true;
            Raf.add(this.onUpdateBind);
        },

        stop() {
            if (!this.isPlaying) return;
            this.isPlaying = false;
            Raf.remove(this.onUpdateBind);
        },

        onUpdate() {
            this.translate.smoothX +=
                (this.translate.x - this.translate.smoothX) *
                this.translate.lerp;
            this.translate.smoothX =
                ((100 * (this.translate.smoothX + 0.01)) | 0) / 100;

            this.translate.smoothY +=
                (this.translate.y - this.translate.smoothY) *
                this.translate.lerp;
            this.translate.smoothY =
                ((100 * (this.translate.smoothY + 0.01)) | 0) / 100;

            this.translateItem(this.translate.smoothX, this.translate.smoothY);
        },

        onMouseMove(e) {
            this.translate.x = e.clientX;
            this.translate.y = e.clientY;

            this.setCursorCoords({
                x: this.translate.x,
                y: this.translate.y,
            });
        },

        onPointerDown() {
            this.holdDown();
        },

        onPointerUp() {
            this.holdUp();
        },

        translateItem(x, y) {
            this.$el.style.transform = `translate3d(${x}px, ${y}px, 0)`;
        },

        holdDown() {
            if (this.holdData.isHolding || this.cursorState != "hold") return;

            // Reset base time
            this.holdData.baseTime = Date.now();

            // Set flag
            this.holdData.isHolding = true;

            // Animate gl hold
            this.holdAnimation?.kill();
            console.log(this.$refs.holdCircle);
            this.holdAnimation = gsap.to(this.$refs.holdCircle, {
                duration: 2,
                attr: {
                    "stroke-dashoffset": 0,
                },
                ease: "linear",
                onComplete: () => {
                    this.holdUp(true);
                },
            });
        },

        holdUp(isComplete = false) {
            if (!this.holdData.isHolding || this.cursorState != "hold") return;
            // Set flag
            this.holdData.isHolding = false;
            // Animate gl hold
            if (isComplete) {
                this.holdCallback?.();
            }

            this.holdAnimation?.kill();
            this.holdAnimation = gsap.to(this.$refs.holdCircle, {
                duration: 0.3,
                attr: {
                    "stroke-dashoffset": 622,
                },
                ease: "power2.out",
            });
        },

        holdCallback() {
            this.incrementHold();
        },
    },
};
</script>