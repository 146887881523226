import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
    currentScroll: 0,
    incrementWheel: 0,
    limitScroll: { x: 0, y: 0 },
    isSmooth: false,
    currentScrollTriggers: []
};

const store = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

export default store;
