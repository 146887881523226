import actions from './actions';
import mutations from './mutations';

const state = {
    cursorState: 'default',
    cursorCoords: {
        x: 0,
        y: 0
    },
    holdIncrement: 0
};

const store = {
    namespaced: true,
    state,
    actions,
    mutations,
};

export default store;
